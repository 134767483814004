import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../app/services/auth.service';
import { SocketService } from '../../../app/services/socket.service';
import { PushNotificationsService } from 'src/app/services/pushNotifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string;
  public password: string;
  public loading: boolean;
  public buttonLoginText: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    public toster: ToastrService,
    public socketService: SocketService,
    private pushService: PushNotificationsService
  ) {
    this.email = ''; this.password = ''; this.loading = false;
    this.buttonLoginText = 'Ingresar';
  }

  ngOnInit() {

  }

  async iniciarSesion() {
    await this.showLoading();
    const result = await this.authService.login(this.email, this.password);

    if (result === null) {
      this.toastFail();
    } else {
      await this.authService.saveToken(result.result, this.email);
      await this.socketService.onLogin();
      this.pushService.requestPermission();
      this.router.navigateByUrl('/chat/app');
      await this.authService.notifyLogin();
    }
    await this.hideLoading();
  }

  async showLoading() {
    this.buttonLoginText = 'Verificando...';
    return;
  }

  async hideLoading() {
    this.buttonLoginText = 'Ingresar';
    return;
  }

  async toastFail() {
    this.toster.error('Usuario o Contraseña incorrecto');
  }

}
