import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService, Menu } from '../../service/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomizerService } from '../../service/customizer.service';
import { UserData } from '../../../../app/models/userData';
import { AuthService } from '../../../../app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { SocketService } from '../../../../app/services/socket.service';

const body = document.getElementsByTagName('body')[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public text: string;
  public openNav: boolean;
  public right_sidebar = false;
  public openToggle: boolean;
  public open: boolean;
  public connState: string;

  public userData: UserData = null;

  private subConnState: Subscription;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  @Output() toggleEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
    private translate: TranslateService,
    public customize: CustomizerService,
    private authService: AuthService,
    private router: Router,
    private toster: ToastrService,
    private socketService: SocketService
  ) {
    translate.setDefaultLang('es');
    this.openNav = false; this.right_sidebar = false;
    this.openToggle = false; this.open = false;
  }

  async init() {
    this.userData = await this.authService.getDataUser();
  }

  async showDialogSesionCopiada() {
    const msg = 'Se detectó que hay otro usuario con un ingreso con'
      .concat('las mismas credenciales, por favor vuelva a iniciar sesión y no comparta sus credenciales con nadie.');
    this.toster.error(msg);
  }

  async close() {
    this.authService.deleteToken();
    this.router.navigateByUrl('/auth/logout').then(() => {
      window.location.reload(true);
    });
  }

  ngOnInit() {
    this.initSocketEvents();
    this.init();
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems;
    });
  }

  async initSocketEvents() {
    this.subConnState = this.socketService.socketConectionState.subscribe((msg) => {
      this.connState = msg;
    });
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openHeaderMenu() {
    this.open = !this.open;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang);
  }

  switchToggle() {
    this.openToggle = !this.openToggle;
    this.toggleEvent.emit(this.openToggle);
  }

}
