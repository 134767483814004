import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: 'auto' | 'ltr' | 'rtl';
  lang?: string;
  vibrate?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  public permission: Permission;

  constructor() {
    this.permission = this.isSupported() ? 'default' : 'denied';
  }

  public isSupported(): boolean {
    return 'Notification' in window;
  }

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission((status) => {
        return this.permission = status;
      });
    }
  }

  create(title: string, options?: PushNotification) {
    let self = this;
    return new Observable((obs) => {
      if (!('Notification' in window)) {
        console.log('Las notificaciones no están disponibles en este ambiente.');
        obs.complete();
      }
      if (self.permission !== 'granted') {
        console.log("El usuario no ha otorgado permisos para enviar notificaciones push.");
        obs.complete();
      }
      let notify = new Notification(title, options);
      notify.onshow = (e) => {
        return obs.next({
          notification: notify,
          event: e
        });
      };
      notify.onclick = (e) => {
        return obs.next({
          notification: notify,
          event: window.focus()
        });
      };
      notify.onerror = (e) => {
        return obs.error({
          notification: notify,
          event: e
        });
      };
      notify.onclose = () => {
        return obs.complete();
      };
    });
  }

  generatePushNotifications(data: any) {
    data.forEach((item: any) => {
      let options = {
        body: item.alertContent,
        icon: "https://pbs.twimg.com/profile_images/1176507032461070336/8Mj1_AKT_400x400.jpg"
      };
      this.create(item.title, options).subscribe();
    })
  }

}