import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nueva-causa',
  templateUrl: './nueva-causa.component.html',
  styleUrls: ['./nueva-causa.component.scss']
})
export class NuevaCausaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
