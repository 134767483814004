import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router) {}

    async init() {
      await this.delay(500)
      this.router.navigateByUrl('auth/login');
    }
  
    delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  
    ngOnInit() {
      this.init(); 
    }

}
