import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { EnvService } from '../env.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationsService } from './pushNotifications.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {

  private socket;
  private actualUrl;
  private userName;
  public previousName;

  private socketConectionStateSubject = new BehaviorSubject<string>('');
  private closeHelpHumanSubject = new BehaviorSubject<string>('');
  private newMessageSubject = new BehaviorSubject<any>('');
  private newHelpSubject = new BehaviorSubject<any>('');
  private userTypingSubject = new BehaviorSubject<any>({});
  private adjudicarSubject = new BehaviorSubject<any>({});
  private adjudicadoSubject = new BehaviorSubject<any>({});

  public socketConectionState = this.socketConectionStateSubject.asObservable();
  public closeHelpHuman = this.closeHelpHumanSubject.asObservable();
  public newMessage = this.newMessageSubject.asObservable();
  public newHelp = this.newHelpSubject.asObservable();
  public userTyping = this.userTypingSubject.asObservable();
  public adjudicar = this.adjudicarSubject.asObservable();
  public adjudicado = this.adjudicadoSubject.asObservable();


  constructor(
    private envService: EnvService,
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private toster: ToastrService,
    private pushService: PushNotificationsService
  ) { }

  public async emit(event: string, data: any) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  public async onLogin() {
    // SOCKET BOT
    if (typeof this.socket === 'undefined') {
      const botServerUrl = await this.envService.getValueFromKey('botServerUrl');
      const aesEmail = localStorage.getItem('email');

      this.socket = io(botServerUrl, {
        reconnection: true, autoConnect: true
      });
      this.socket.on('connection', data => {
        this.socket.emit('setAgent', { email: aesEmail });
        this.socketConectionStateSubject.next('connection');
      });

      this.socket.on('connect_error', data => {
        this.socketConectionStateSubject.next('connect_error');
      });

      this.socket.on('reconnect', data => {
        this.socketConectionStateSubject.next('reconnect');
      });

      this.socket.on('reconnect_error', data => {
        this.socketConectionStateSubject.next('reconnect_error');
      });

      this.socket.on('reconnect_failed', data => {
        this.socketConectionStateSubject.next('reconnect_failed');
      });

      this.socket.on('reconnect_attempt', data => {
        this.socketConectionStateSubject.next('reconnect_attempt');
      });

      this.router.events.subscribe((navigationEnd: any) => { this.actualUrl = navigationEnd.url; });

      this.socket.on('closeHelpHuman', async (msg) => {
        const conversation = msg.conversation;
        this.closeHelpHumanSubject.next(conversation);
      });

      this.socket.on('newMessage', async (msg) => {
        const chatList = JSON.parse(localStorage.getItem('chatList'));
        if (this.chatExist(chatList, msg.conversation)) {
          if (this.actualUrl && this.actualUrl !== '/chat/app' && this.actualUrl !== '') {
            this.info('Nuevo mensaje!');
          }
          if (document.visibilityState === 'hidden') {
            if (this.previousName !== this.userName) {
              this.previousName = this.userName;
              this.pushService.generatePushNotifications([{ title: 'Nuevo mensaje!', alertContent: 'Tiene un nuevo mensaje de ' + this.userName + '.' }]);
            }
          }
          this.newMessageSubject.next(msg);
        }
      });

      this.socket.on('typingUser', async (data) => {
        this.userTypingSubject.next(data);
      });

      this.socket.on('adjudicarse', async (data) => {
        this.adjudicarSubject.next(data);
      });

      this.socket.on('adjudicado', async (data) => {
        if (data.agentEmail !== localStorage.getItem('email')) {
          this.toster.success('Conversación con ' + data.userName + ' (' + data.channel + ') adjudicada por ' + data.agentFullName);
        }
        this.adjudicadoSubject.next(data);
      });

      this.socket.on('newHelp', (msg) => {
        if (document.visibilityState === 'hidden') {
          this.titleService.setTitle('Nuevo Chat!');
          this.pushService.generatePushNotifications([{ title: 'Nuevo Chat!', alertContent: 'Una persona necesita de tu ayuda.' }]);
        }
        this.info('Nuevo Chat!')
        // this.events.publish('socketCountListaEspera', msg.count);
        this.newHelpSubject.next(msg);
      });

      this.socket.on('newLogin', async (msg) => {
        const token = msg.token;
        const sessionToken = msg.sessionToken;
        const myToken = await this.authService.getTokenUser();
        const mySessionToken = await this.authService.getSessionBrowser();
        if (token === myToken && sessionToken !== mySessionToken) {
          this.showDialogSesionCopiada();
          this.authService.deleteToken();
          await this.delay(3000);
          this.router.navigateByUrl('/auth/logout').then(() => {
            window.location.reload(true);
          });
        }
      });
    }

    // SOCKET BACKEND
    /* const backendServerUrl = await this.envService.getValueFromKey('backendServerUrl');
    const socketBackEnd = io(backendServerUrl);

    socketBackEnd.on('awaitUpdate', (msg) => {
      console.log('awaitUpdate socketBackEnd');
      this.events.publish('awaitUpdate', msg, Date.now());
    }); */
  }

  private async showDialogSesionCopiada() {
    const msg = 'Se detectó otro usuario que ingreso con ' +
      'las mismas credenciales, por favor vuelva a iniciar sesión y no comparta sus credenciales con nadie.';
    this.toster.error(msg);
  }

  private async info(msg: string) {
    this.toster.info(msg);
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  chatExist(chatList: any, conversation: string) {
    for (const chat of chatList) {
      if (chat.conversation === conversation) {
        let nombreUsuario = '';
        nombreUsuario = chat.conversationReference.from.name.split(' ')[0];
        nombreUsuario = nombreUsuario.toLowerCase();
        nombreUsuario = nombreUsuario.charAt(0).toUpperCase() + nombreUsuario.slice(1);
        this.userName = nombreUsuario;
        return true;
      }
    }
    return false;
  }

  public async resetObservable() {
    this.adjudicarSubject.next(null);
  }

}
