import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  headTitle?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  children?: Menu[];
}

// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class NavService {
  public screenWidth: any;
  public openToggle: boolean = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 1199) {
      this.openToggle = true;
    }
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  // tslint:disable-next-line:member-ordering
  MENUITEMS: Menu[] = [
    {
      headTitle: 'Administración'
    },
    {
      path: '/humans/app', title: 'Agentes y Administradores', icon: 'icon-user', type: 'link'
    },
    {
      path: '/metrix/app', title: 'Métricas Atención', icon: 'icon-pie-chart', type: 'link'
    },
    {
      path: '/config/app', title: 'Configuración', icon: 'icon-panel', type: 'link'
    },
    {
      headTitle: 'Chats'
    },
    {
      path: '/metrix-agent/app', title: 'Mis Métricas', icon: 'icon-pie-chart', type: 'link'
    },
    {
      path: '/chat/app', title: 'Chats Activos', icon: 'icon-headphone-alt', type: 'link'
    },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
