import { Injectable } from '@angular/core';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from './../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnvService {

  private envData: any = null;
  constructor() {
    this.init();
  }

  async init() {
    const file = environment.client;
    await this.readJsonFile(file);
  }

  private async sleep() {
    return new Promise(r => setTimeout(r, 1000));
  }
  public async getValueFromKey(key: string) {
    if (this.envData === null) { await this.sleep(); }
    return this.envData[key];
  }

  private async readJsonFile(env: string) {
    const url = encodeURI('assets/' + env + '.json');
    const request = {
      method: 'get',
      url,
    } as AxiosRequestConfig;
    const responce = (await axios(request)) as any;
    this.envData = responce.data;
  }
}
