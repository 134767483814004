export class ConfigDB {
	static data = {
        mainThemeLayout:'main-theme-layout-5',
        menuLayout:'default',
        scroll:'custom-scrollbar',
        borderNavigation:'',
        rightSidebarIcon:'',
        backgroungImage:'',
        defaultNavigation:'',
        header:{
            semiLightbgColor:'',
            headerBgColor:''
        }
    }
}