import { Injectable } from '@angular/core';
import { EnvService } from '../env.service';
import axios, { AxiosRequestConfig } from 'axios';
import { UserData } from '../models/userData';
import { v4 as uuid } from 'uuid';
import { AES, enc } from 'crypto-ts';
import { Human } from '../models/Human';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private envService: EnvService) { }

  public async login(email: string, password: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/login');

    const request = {
      method: 'post',
      url,
      data: {
        key: email, password
      }
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data as any;
    } catch (e) {
      return null;
    }
  }

  public async notifyLogin() {
    const serverUrl = await this.envService.getValueFromKey('botServerUrl');
    const url = encodeURI(serverUrl + '/api/messages/handoff/notifyLogin');
    const sessionToken = await this.getSessionBrowser();
    const token = await this.getTokenUser();
    const request = {
      method: 'post',
      url,
      data: {
        token, sessionToken,
      }
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data as any;
    } catch (e) {
      return null;
    }
  }

  private key = '5623ee2a-3d8f-4285-a4c4-023f7bae3a3b';

  public async isValidToken() {
    const token: string = await this.getToken();
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/checkValid');
    const request = {
      method: 'post',
      url,
      headers: {
        Authorization: 'Bearer ' + token,
      }
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data as any;
    } catch (e) {
      return false;
    }
  }

  public async saveToken(token: string, email: string) {
    localStorage.setItem('id_token', token);
    const aesEmail = AES.encrypt(email, this.key).toString();
    localStorage.setItem('email', aesEmail);
  }

  public async getEmail(): Promise<string> {
    const aesEmail = localStorage.getItem('email');
    const email = AES.decrypt(aesEmail, this.key).toString(enc.Utf8);
    return email;
  }

  public async haveRole(key: string) {
    const userData: UserData = await this.getDataUser();
    if (!userData.roles) { return false; }
    for (const role of userData.roles) {
      if (role.key === key) { return true; }
    }
    return true;
  }

  public async getTokenUser() {
    const userData: UserData = await this.getDataUser();
    if (!userData) { return null; }
    return userData.token;
  }

  public async getToken(): Promise<string> {
    return localStorage.getItem('id_token');
  }

  public async getBearerTokenHeader() {
    const token = await this.getToken();
    return { authorization: 'Bearer ' + token };
  }

  public async getDataUser(): Promise<UserData> {
    try {
      const token = await this.getToken();
      const base64 = token.split('.')[1];
      const stringDecode = atob(base64);
      return JSON.parse(stringDecode) as UserData;
    } catch (e) {
      console.error('getDataUser - AuthService');
      return null as UserData;
    }
  }

  public async deleteToken() {
    localStorage.clear();
  }

  public async getSessionBrowser() {
    const sessionB = localStorage.getItem('sessionBrowser');
    if (!sessionB) {
      const token = uuid();
      localStorage.setItem('sessionBrowser', token);
      return token;
    }
    return sessionB;
  }

  public async getAllHumans() {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/list');
    console.log(1);
    console.log(url);
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result as [Human];
    } catch (e) {
      return null;
    }
  }

  public async createUser(email: string, nombre: string, apellido: string, password: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/create');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'put',
      url,
      headers,
      data: { email, nombre, apellido, password, rut: 'n/a', }
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async blockUnblock(email: string, block: boolean = true) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    let urlMethod = '/unblock';
    if (!block) { urlMethod = '/block'; }
    const url = encodeURI(serverUrl + urlMethod);
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      headers,
      data: { email }
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async resend(email: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/resend');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      data: { email },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async delete(email: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'delete',
      url,
      data: { email },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async changePassword(email: string, newPassword: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/changePassword');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      data: { email, newPassword },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async changeUser(emailStart: string, email: string, nombre: string, apellido: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/changeUser');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      data: { emailStart, email, nombre, apellido },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async setRoles(email: string, role: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/rolesAdmin');
    const headers = await this.getBearerTokenHeader();

    const request = {
      method: 'put',
      url,
      data: { email, role },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      console.log(22222);
      console.log(responce);
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async deleteRoles(token: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/roles');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'delete',
      url,
      data: { token },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }

  public async getRoles(token: string) {
    const serverUrl = await this.envService.getValueFromKey('SsoServerUrl');
    const url = encodeURI(serverUrl + '/roles');
    const headers = await this.getBearerTokenHeader();
    const request = {
      method: 'post',
      url,
      data: { token },
      headers,
    } as AxiosRequestConfig;
    try {
      const responce = (await axios(request)) as any;
      return responce.data.result;
    } catch (e) {
      return null;
    }
  }
}
