import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../guard/admin.guard';

export const content: Routes = [
  {
    path: 'chat',
    loadChildren: () => import('../../apps/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'metrix',
    loadChildren: () => import('../../apps/metrix/metrix.module').then(m => m.MetrixModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'humans',
    loadChildren: () => import('../../apps/humans/humans.module').then(m => m.HumansModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'config',
    loadChildren: () => import('../../apps/config/config.module').then(m => m.ConfigModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'metrix-agent',
    loadChildren: () => import('../../apps/metrix-agent/metrix-agent.module').then(m => m.MetrixAgentModule),
    canActivate: [AdminGuard],
  },
];
