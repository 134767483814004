import { Component } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from './services/socket.service';
import { collapseAnimation } from 'angular-calendar';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private socketService: SocketService, private sso: AuthService) {
    this.closeAnimation();
    this.checkLogin();
  }

  async checkLogin() {
    const token: string = await this.sso.getToken();
    if (token) {
      // const isValid = await this.sso.isValidToken();
      // console.log({ isValid });
      // if (isValid) {
        this.socketService.onLogin();
      // } 
    }
  }

  closeAnimation() {
    NgbModalRef.prototype['c'] = NgbModalRef.prototype.close;
    NgbModalRef.prototype.close = function (reason: string) {
      document.querySelector('.modal-backdrop').classList.remove('show');
      document.querySelector('.modal').classList.remove('show');
      setTimeout(() => {
        this['c'](reason);
      }, 500);
    };
    NgbModalRef.prototype['d'] = NgbModalRef.prototype.dismiss;
    NgbModalRef.prototype.dismiss = function (reason: string) {
      document.querySelector('.modal-backdrop').classList.remove('show');
      document.querySelector('.modal').classList.remove('show');
      setTimeout(() => {
        this['d'](reason);
      }, 500);
    };
  }
}
